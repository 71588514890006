import { AccountService } from "./account";
import { BookingService } from "./bookings/BookingService";
import { EventsService } from "./events/EventsService";
import { OrderService } from "./order/OrderService";
import { PaymentService } from "./payment/PaymentService";

type ApiFacade = {
    events: EventsService,
    payments: PaymentService,
    accounts: AccountService,
    orders: OrderService,
    bookings: BookingService
}

class ApiFacadeSingleton {
    readonly facade: ApiFacade | null = null

    constructor() {
        if (!this.facade) {
            this.facade = {
                events: new EventsService(),
                payments: new PaymentService(),
                accounts: new AccountService(),
                orders: new OrderService(),
                bookings: new BookingService(),
            }
        }
    }

    get _() {
        if (!this.facade) {
            throw new Error("ApiFacadeSingleton not initialized")
        }
        return this.facade
    }
}

/**
 * The Api facade singleton instance to use throughout the application
 */
export const Api = new ApiFacadeSingleton()._
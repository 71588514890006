import React from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Outlet } from "react-router-dom";
import  Logo  from "../../logowithname.png"

export const AnonymousLayout: React.FC = () => {
    console.log("Rendering AnonymousLayout")
    return (
        <>
            <Navbar style={{backgroundColor: "#f1c400"}}>
                <Container>
                <Navbar.Brand href="/">
                    <img src={Logo} width={180} style={{ maxWidth: "100%" }} />
                </Navbar.Brand>


                {/* <Nav>
                    <Link className="nav-link text-white" to="account/register"><b> <Icon.PersonAdd /> Register</b></Link>
                    <Link className="nav-link text-white" to="account/login"><b><Icon.Lock /> Login</b></Link>
                </Nav> */}
                </Container>
            </Navbar>

            <Container fluid className="py-5">
                <Outlet />
            </Container>
        </>
    );
}
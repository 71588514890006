import { FC } from "react"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { AnEvent, EventTicketQuantity, EventVenue } from "../../api/events"
import { CalendarDate, GeoAlt } from "react-bootstrap-icons"

export type UICardProps = {
    /**
     * Event data
     */
    event: AnEvent
    /**
     * Event venue data
     */
    venue: EventVenue
    /**
     * Event ticket data
     */
    tickets: Array<EventTicketQuantity>
    /**
     * Image url
     */
    image: string
    /**
     * Link to event page
     */
    link: string
}

const StyledCard = styled.div`
    box-shadow: 0px 5px 8px 6px rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    text-align: center;
`
export const UIEventCard: FC<UICardProps> = ({
    event,
    venue,
    tickets,
    image,
    link,
}) => {
    const getStartingPrice = (tickets: Array<EventTicketQuantity>): number => {
        let startingPrice = tickets[0].price
        tickets.forEach((ticket) => {
            if (ticket.price < startingPrice) {
                startingPrice = ticket.price
            }
        })
        return startingPrice
    }

    return (
        <StyledCard>
            <Card style={{ border: "none" }}>
                <Card.Header>
                    <small className="text-muted">
                        <CalendarDate/> {" "}
                        From {new Date(event.startDate).toDateString()} -{" "}
                        {new Date(event.endDate).toDateString()}
                    </small>
                </Card.Header>
                <Card.Img variant="top" src={image} />
                <Card.Body>
                    <Card.Title>{event.eventName}</Card.Title>
                    <Card.Text className="text-muted">
                        <GeoAlt/> {" "} {venue.googleLocation}, {venue.province}{" "}
                        <b> @{venue.venue}</b>
                    </Card.Text>
                    <Link
                        className="btn btn-block"
                        style={{
                            color: "#fff",
                            backgroundColor: "#ffc107",
                            borderColor: "#ffc107",
                        }}
                        to={link}
                    >
                        Tickets from{" "}
                        <b>R{getStartingPrice(tickets).toFixed(2)}</b>
                    </Link>
                </Card.Body>
            </Card>
        </StyledCard>
    )
}

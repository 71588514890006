import React from "react"
import { Navbar, Container, Nav } from "react-bootstrap"
import { Outlet, Navigate, Link } from "react-router-dom"
import { LocalAuth } from "./LocalAuth"

const PrivateRoutes = () => {
    return (
        <React.Fragment>
            {LocalAuth.isAuthenticated() ? (
                <>
                    <Navbar bg="dark" variant="dark">
                        <Container>
                            <Navbar.Brand href="#home">ePay</Navbar.Brand>
                            <Nav className="mr-auto">
                                <Link className="nav-link" to="events">
                                    Events
                                </Link>
                            </Nav>
                        </Container>
                    </Navbar>

                    <Container fluid className="px-5 py-5">
                        <Outlet />
                    </Container>
                </>
            ) : (
                <Navigate to="/account/login" />
            )}
            \
        </React.Fragment>
    )
}

export default PrivateRoutes

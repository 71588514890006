import { AxiosStatic } from "axios"
import { httpClient } from "../Interceptor"
import { EventVenue, EventApiResponse } from "./types"


export class EventsService {
    readonly http: AxiosStatic

    constructor() {
        this.http = httpClient
    }

    /**
     * Gets a list of events
     * @param searchTerm The search term to filter events by
     * @returns 
     */
    async getEvents(searchTerm: string): Promise<EventApiResponse[]> {
        return this.http.get<EventApiResponse[]>(`/api/Events/GetEvents?search=${searchTerm}`)
            .then((response) => response.data)
    }

    /**
     * Get a single event by id
     * @param uuid The id of the event to get
     * @returns 
     */
    async getEventById(eventId: string): Promise<EventApiResponse> {
        return this.http.get<EventApiResponse>(`/api/Events/GetEvent?eventId=${eventId}`)
            .then((response) => response.data)
    }

    /**
     * Get a list of venues for an event
     * @param eventVenueId The id of the event to get venues for
     * @returns 
     */
    async getEventVenue(eventVenueId: string): Promise<EventVenue> {
        return this.http.get<EventVenue>(`/api/Events/GetEventVenue?eventVenueId=${eventVenueId}`)
            .then((response) => response.data)
    }

    /**
     * Get event venue by event id
     * @param eventId The id of the event to get venues for
     * @returns 
     */
    async getEventVenueById(eventId: string): Promise<EventVenue> {
        return this.http.get<EventVenue[]>(`/api/Events/GetEventVenues?eventId=${eventId}`)
            .then((response) => response.data[0])
    }
}
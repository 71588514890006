import { AxiosStatic } from "axios"
import { httpClient } from "../Interceptor"
import { CreateUserApiRequest, LoginApiRequest } from "./types"

export class AccountService {
    readonly http: AxiosStatic
    constructor() {
        this.http = httpClient
    }

    /**
     * Create a user account
     * @param request - user account request body
     * @returns
     */
    async createUser(request: CreateUserApiRequest) {
        return this.http.post<{
            error: string, token: string 
}>(`/api/Accounts/Register`, request)
            .then((response) => response.data)
    }

    /**
     * Login to a user account
     * @param username - user account username
     * @returns
     */
    async login(request: LoginApiRequest) {
        return this.http.post<{ error: string, token: string }>(`/api/Accounts/Login`, request)
            .then((response) => response.data)
    }
}
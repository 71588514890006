import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Api } from "../../api"
import { EventApiResponse } from "../../api/events/types"
import { Badge, Col, Container, Row } from "react-bootstrap"
import { LocalAuth } from "../../auth"

export const EventDetailsPage: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const [event, setEvent] = useState<EventApiResponse>()

    useEffect(() => {
        const getEventVenue = async () => {
            if (!id) throw new Error("No id provided")
            const response = await Api.events.getEventById(id)
            setEvent(response)
        }
        getEventVenue()
    }, [id])

    console.log("Rendering EventDetailsPage")

    return (
        <Container>
            {event?.event ? (
                <Row>
                    <Col md={8}>
                        <h2 className="pt-2 pb-2">{event?.event.eventName}</h2>
                        <p className="lead">{event?.event.eventDetails}</p>
                    </Col>
                    <Col md={4}>
                        <h5 className="mt-2 lead font-weight-bold">
                            Book your ticket now
                        </h5>
                        <hr />

                        <img
                            src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0b/c8/4b/6e/moses-mabhida-with-top.jpg?w=1200&h=-1&s=1"
                            alt="event"
                            className="img-fluid pb-2 pt-2"
                        />
                        {event.eventTickets.map((ticket) => (
                            <Row key={ticket.id}>
                                <Col md={5}>
                                    <h5>
                                        <Badge
                                            style={{ color: "#FFF" }}
                                            bg="secondary"
                                        >
                                            R {ticket.price.toFixed(2)} (
                                            {ticket.ticketType})
                                        </Badge>
                                    </h5>
                                </Col>
                                <Col md={7}>
                                    <i>
                                        {ticket.quantity - ticket.quantitySold}{" "}
                                        tickets available!
                                    </i>
                                </Col>
                            </Row>
                        ))}
                        <p className="lead font-weight-bold mt-4">Venue(s)</p>
                        <hr />
                        {event?.venues.map((venue) => (
                            <div key={venue.id}>
                                <Row key={venue.id}>
                                    <Col md={8}>
                                        <p className="lead">{venue.venue}</p>
                                    </Col>
                                    <Col md={4}>
                                        <Link
                                            to={
                                                LocalAuth.isAuthenticated()
                                                    ? `/checkout/event/${event.event.id}/venue/${venue.id}`
                                                    : `/guest/checkout/event/${event.event.id}/venue/${venue.id}`
                                            }
                                            className="btn btn-sm btn-dark"
                                        >
                                            Buy Tickets
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <p className="text-muted">
                                            <span className="font-weight-bold">
                                                Location:
                                            </span>{" "}
                                            {venue.googleLocation},{" "}
                                            {venue.province}
                                        </p>
                                        <p className="text-muted">
                                            <span className="font-weight-bold">
                                                Venue Date:
                                            </span>{" "}
                                            {new Date(
                                                venue.eventVenueDateForUse
                                            ).toDateString()}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <hr />
                        <p className="lead font-weight-bold">
                            Additional Information
                        </p>
                        <hr />
                        <p className="text-muted">
                            <span className="font-weight-bold">Date(s):</span>{" "}
                            {new Date(event?.event.startDate).toDateString()} -{" "}
                            {new Date(event?.event.endDate).toDateString()}
                        </p>
                        <p className="text-muted">
                            <span className="font-weight-bold">Time:</span>{" "}
                            {new Date(
                                event?.event.startDate
                            ).toLocaleTimeString()}{" "}
                            -{" "}
                            {new Date(
                                event?.event.endDate
                            ).toLocaleTimeString()}
                        </p>
                        <p className="text-muted">
                            <span className="font-weight-bold">
                                Age Restriction:{" "}
                            </span>
                            {event?.event.ageRestriction ? (
                                <>{`${event.event.ageRestriction} years`}</>
                            ) : (
                                <>No age restriction</>
                            )}
                        </p>

                        <hr />
                    </Col>
                </Row>
            ) : (
                <p>Loading...</p>
            )}
        </Container>
    )
}

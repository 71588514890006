import { useState } from "react"
import { Api } from "../../api"
import { Button, Col, Container, Form, Row, Card } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { LocalAuth } from "../../auth"
import { Toaster, toast } from "sonner"
import  LogoWithoutName  from "../../logo.png"

export const LoginPage: React.FC = () => {
    const navigate = useNavigate()
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    const loginHandler = async () => {
        if(username.trim() === ''){
            toast.error("Email is required.")
            return;
          }

          if(password.trim() === ''){
            toast.error("Password is required.")
            return;
          }

        const response = await Api.accounts.login({ username, password })

        if(response.error != ''){
            toast.error(response.error)
        }else{
            LocalAuth.setSession(response.token)
            navigate("/events")
        }   
    }
    return (
        <Container>
            <Toaster richColors position="top-right" />
            <Row>
                <Col md={2}>
                </Col>
                <Col md={8}>
                <Card className="p-5 shadow">
                    <div className="text-center">
                    <img src={LogoWithoutName} width={70} />
                    <h1 className="lead pb-4">Login User</h1>
                    </div>
                 
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                type="email"
                                placeholder="Enter email"
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                        >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="formBasicCheckbox"
                        >
                        </Form.Group>
                        <Button className="btn btn-block mt-4" style={{backgroundColor: "#f1c400", 
                    borderColor: "#f1c400"}} onClick={loginHandler}>
                            Login
                        </Button>
                    </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import "bootstrap/dist/css/bootstrap.min.css"
import {
    createBrowserRouter,
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom"
import { AnonymousLayout } from "./components/layouts/Anonymous"
import { ContactPage } from "./pages/Contact"
import { EventsPage } from "./pages/events/Events"
import { EventDetailsPage } from "./pages/events/EventDetails"
import { CheckoutGuestPage } from "./pages/checkout/CheckoutGuest"
import { PaymentConfirmationPage } from "./pages/checkout/PaymentConfirmation"
import { CheckoutPage } from "./pages/checkout/Checkout"
import { RegisterPage } from "./pages/account/Register"
import { LoginPage } from "./pages/account/Login"
import { BookingPage } from "./pages/bookings/Booking"
import { BookingConfirmationPage } from "./pages/bookings/BookingConfirmation"
import PrivateRoutes from "./auth/PrivateRoute"
import { NoNavbarLayout } from "./components/layouts/NoNavBar"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route element={<PrivateRoutes />}>
                    <Route
                        element={<CheckoutPage />}
                        path="checkout/event/:eventId/venue/:venueId"
                    />
                </Route>
                <Route element={<AnonymousLayout />}>
                    <Route element={<EventsPage />} path="/" />
                    <Route element={<EventsPage />} path="events" />
                    <Route element={<EventDetailsPage />} path="events/:id" />
                    <Route element={<ContactPage />} path="contacts" />
                    <Route element={<RegisterPage />} path="account/register" />
                    <Route element={<LoginPage />} path="account/login" />
                    <Route
                        element={<CheckoutGuestPage />}
                        path="guest/checkout/event/:eventId/venue/:venueId"
                    />
                    <Route
                        element={<PaymentConfirmationPage />}
                        path="payment/:orderId/status"
                    />
                </Route>
                <Route element={<NoNavbarLayout />}>
                    <Route element={<BookingPage />} path="bookings/create" />
                    <Route
                        element={<BookingConfirmationPage />}
                        path="bookings/confirmation/:bookingId"
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

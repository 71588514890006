import { useState } from "react"
import { Api } from "../../api"
import {
    Button,
    Col,
    Container,
    Form,
    Row,
    Card,
    FormSelect,
} from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { Toaster, toast } from "sonner"
import LogoWithoutName from "../../logo.png"
import "react-datepicker/dist/react-datepicker.css"

export const BookingPage: React.FC = () => {
    const navigate = useNavigate()
    const [firstname, setName] = useState("")
    const [lastname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")
    const [massageType, setMassageType] = useState("")
    const [massageDate, setMassageDate] = useState("")
    const [isDisabled, setDisabled] = useState(false)

    const bookingHandler = async () => {
        if (firstname.trim() === "") {
            toast.error("Name is required.")
            return
        }

        if (lastname.trim() === "") {
            toast.error("Surname is required.")
            return
        }

        if (email.trim() === "") {
            toast.error("Email is required.")
            return
        }

        if (contact.trim() === "") {
            toast.error("Contact is required.")
            return
        }

        if (massageType.trim() === "") {
            toast.error("Massage type is required.")
            return
        }

        if (massageDate.trim() === "") {
            toast.error("Massage date is required.")
            return
        }

        setDisabled(true)
        const response = await Api.bookings.createBooking({
            firstname,
            lastname,
            email,
            contact,
            massageType,
            massageDate,
        })

        if (response.error != "") {
            console.log(response)
            setDisabled(false)
        } else {
            navigate(`/bookings/confirmation/${response.bookingId}`)
        }
    }
    return (
        <Container style={{ paddingTop: "35px", paddingBottom: "50px" }}>
            <Toaster richColors position="top-right" expand={true} />
            <Row>
                <Col md={3}></Col>
                <Col md={6}>
                    <Card className="p-5 shadow">
                        <div className="text-center">
                            <img src={LogoWithoutName} width={70} />
                            <h1 className="lead pb-4">Create New Booking</h1>
                        </div>

                        <Form>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicName"
                            >
                                <Form.Label>Firstname</Form.Label>
                                <Form.Control
                                    value={firstname}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    placeholder="Firstname"
                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicSurname"
                            >
                                <Form.Label>Surname</Form.Label>
                                <Form.Control
                                    value={lastname}
                                    onChange={(e) => setSurname(e.target.value)}
                                    type="text"
                                    placeholder="Lastname"
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    placeholder="Enter email"
                                />
                                <Form.Text className="text-muted">
                                    We'll never share your email with anyone
                                    else.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="formBasicContact"
                            >
                                <Form.Label>Contact</Form.Label>
                                <Form.Control
                                    value={contact}
                                    onChange={(e) => setContact(e.target.value)}
                                    type="text"
                                    placeholder="Contact"
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Massage Type</Form.Label>
                                <Form.Select
                                    className="form-control"
                                    value={massageType}
                                    onChange={(e) =>
                                        setMassageType(e.target.value)
                                    }
                                    aria-label="Default select example"
                                >
                                    <option>Select Massage</option>
                                    <option value="Full massage R590 - 30 minutes">
                                        Full massage R590 - 30 minutes
                                    </option>
                                    <option value="Full massage with stones R790 - 30 minutes">
                                        Full massage with stones R790 - 30
                                        minutes
                                    </option>
                                    <option value="Leg Massage (R400) - 30 Minutes">
                                        Leg Massage (R400) - 30 Minutes
                                    </option>
                                    <option value="Foot Massage (R300) - 15 Minutes">
                                        Foot Massage (R300) - 15 Minutes
                                    </option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicConfirmPassword"
                            >
                                <Form.Label>Massage Date</Form.Label>
                                <Form.Control
                                    value={massageDate}
                                    onChange={(e) =>
                                        setMassageDate(e.target.value)
                                    }
                                    type="date"
                                    placeholder="Select Date"
                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicCheckbox"
                            ></Form.Group>

                            <Button
                                className="btn btn-block mt-4"
                                style={{
                                    backgroundColor: "#f1c400",
                                    borderColor: "#f1c400",
                                }}
                                onClick={bookingHandler}
                                disabled={isDisabled}
                            >
                                {isDisabled
                                    ? "Please wait.."
                                    : "Confirm Booking"}
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { Api } from "../../api"
import React, { useEffect, useState } from "react"
import { Order } from "../../api/order/types"
import { CheckCircle } from "react-bootstrap-icons"

export const PaymentConfirmationPage: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>()
    const [order, setOrder] = useState<Order | null>(null)

    useEffect(() => {
        const getOrder = async () => {
            if (!orderId) return
            const response = await Api.orders.getOrder(orderId)
            setOrder(response)
        }
        getOrder()
    }, [orderId])

    return (
        <Container>
            <React.Fragment>
                {!order ? (
                    <p>Loading...</p>
                ) : (
                    <Row className="text-center">
                        <Col md={12}>
                            <h2 className="pt-2 pb-2">
                                Ticket Purchase Confirmation
                            </h2>
                            <CheckCircle size={64} color="green" />

                            <p className="lead pt-4">
                                Thank you for your purchase! Please check your
                                email for your tickets.
                            </p>
                            <p className="lead pb-4">
                                Reference:{" "}
                                <b>
                                    <code>{order?.orderReference}</code>
                                </b>
                            </p>
                        </Col>
                        <Col md={12}>
                            <h4 className="pt-4 pb-2">Order Details</h4>
                            <p>
                                <b>Amount:</b> R{order?.totalPrice.toFixed(2)}
                            </p>
                            <p>
                                <b>Order ID:</b> {order?.id}
                            </p>
                            <p>
                                <b>Order Reference:</b> {order?.orderReference}
                            </p>
                            <p>
                                <b>Owner</b> {order?.userId}
                            </p>
                        </Col>
                    </Row>
                )}
            </React.Fragment>
        </Container>
    )
}

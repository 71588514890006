import React from "react"
import Container from "react-bootstrap/Container"
import { Outlet } from "react-router-dom"
import BackgroundImage from "../../assets/images/hikebg.png"

export const NoNavbarLayout: React.FC = () => {
    return (
        <div
            style={{
                backgroundImage: "url(" + BackgroundImage + ")",
                backgroundSize: "cover",
            }}
        >
                <Outlet />
        </div>
    )
}

import axios, { InternalAxiosRequestConfig } from "axios"

const baseURL = process.env.REACT_APP_API_BASE_URL


axios.defaults.timeout = 10_000
axios.defaults.baseURL = baseURL
// axios.defaults.withCredentials = true

if (!baseURL) {
    throw new Error('REACT_APP_API_BASE_URL is not set')
}

axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)

export const httpClient = axios

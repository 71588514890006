import { Modal, Button } from "react-bootstrap"

type ModalProps = {
    /**
     * The title of the modal
     */
    heading: string
    /**
     * The body of the modal
     */
    body: {
        /**
         * The text of the body
         */
        text: string

        /**
         * The payment reference
         */
        reference?: string
    }
    /**
     * Show or hide the modal
     */
    show: boolean
    /**
     * Function to hide the modal
     */
    onHide: () => void
}
export const VerticalModal: React.FC<ModalProps> = ({
    heading,
    body,
    show,
    onHide,
}) => {
    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {heading}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{body.text}</p>
                {body.reference && (
                    <p className="lead">
                        Payment reference:{" "}
                        <span className="font-weight-bold">
                            {body.reference}
                        </span>
                    </p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

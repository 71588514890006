import React, { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import {
    Button,
    ButtonGroup,
    Card,
    Col,
    Container,
    Form,
    Row,
} from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { Api } from "../../api"
import { EventApiResponse, EventVenue } from "../../api/events"
import { CheckoutRequest, GuestCustomer } from "../../api/payment/types"
import { VerticalModal } from "../../components/modals"
import { ExclamationTriangleFill, Lock } from "react-bootstrap-icons"
import { Toaster, toast } from "sonner"

type CartItem = {
    ticketId: string
    quantity: number
    ticketType: string
    price: number
}
export const CheckoutGuestPage: React.FC = () => {
    const navigate = useNavigate()
    const params = useParams<{ eventId: string; venueId: string }>()

    const [yoco, _setYoco] = useState<any>((window as any).yoco)
    const [venue, setVenue] = useState<EventVenue>()
    const [event, setEvent] = useState<EventApiResponse>()
    const [eventId, _setEventId] = useState<any>(params.eventId)
    const [venueId, _setVenueId] = useState<any>(params.venueId)
    const [quantity, setQuantity] = useState<number>(0)
    const [ticketCart, setTicketCart] = useState<CartItem[]>([])
    const [paymentStatus, setPaymentStatus] = useState<{
        status: "APPROVED" | "DECLINED"
        responseHeader: string
        responseBody: string
        reference?: string
    }>()
    const [disablePayButton, setDisablePayButton] = useState(true)

    // Payment status modal
    const [modalShow, setModalShow] = useState(false)

    // customer details
    const [customer, setCustomer] = useState<GuestCustomer>({
        id: uuidv4(),
        userId: uuidv4(),
        firstname: "",
        lastname: "",
        contact: "",
        email: "",
        isContactAlsOnWhatsapp: false,
        customerType: "Guest",
    })

    const addToCart = (item: CartItem) => {
        const index = ticketCart.findIndex(
            (ticket) => ticket.ticketId === item.ticketId
        )
        if (index > -1) {
            const newTicketCart = [...ticketCart]
            newTicketCart[index].quantity += 1
            setTicketCart(newTicketCart)
            setQuantity(newTicketCart[index].quantity)
        } else {
            setTicketCart([...ticketCart, item])
            setQuantity(1)
        }

        setDisablePayButton(false)
    }

    const removeFromCart = (item: CartItem) => {
        const index = ticketCart.findIndex(
            (ticket) => ticket.ticketId === item.ticketId
        )
        if (item.quantity > -1) {
            if (index > -1) {
                const newTicketCart = [...ticketCart]
                newTicketCart[index].quantity -= 1
                setTicketCart(newTicketCart)
                setQuantity(newTicketCart[index].quantity)

                if (newTicketCart[index].quantity == 0) {
                    setDisablePayButton(true)
                }
            }
        } else {
            setDisablePayButton(true)
        }
    }

    useEffect(() => {
        if (!yoco) throw new Error("Error: Yoco payment gateway not loaded")

        const getEventVenue = async () => {
            const response = await Api.events.getEventVenue(venueId)
            setVenue(response)
        }

        const getEvent = async () => {
            const response = await Api.events.getEventById(eventId)
            setEvent(response)
        }

        getEventVenue()
        getEvent()
    }, [eventId, venueId, yoco])

    const createOrder = async () => {
        const tickets: CheckoutRequest = ticketCart.map((item, index) => {
            return {
                eventId: event!.event.id,
                eventVenueId: venue!.id,
                price: item.price,
                ticketType: item.ticketType,
                quantity: item.quantity,
            }
        })
        const guest: GuestCustomer = customer
        const response = await Api.payments.createOrderForGuest({
            guestCustomer: guest,
            orderTickets: tickets,
        })
        return response
    }

    const handleCheckout = async () => {
        if (Object.values(customer).some((value) => value === "")) {
            toast("Validation Error", {
                description: "Please fill in all the required fields",
                icon: <ExclamationTriangleFill />,
                important: true,
                style: {
                    borderRadius: "10px",
                    background: "#f8d7da",
                    color: "#721c24",
                    borderColor: "#f5c6cb",
                }
            })
            return
        }
        const response = await createOrder()
        yoco?.showPopup({
            amountInCents: `${response.orderDetails.totalPrice * 100}`,
            currency: "ZAR",
            name: `${event?.event.eventName} @ ${venue?.venue}`,
            description: `Tickets for ${event?.event.eventName} @ ${venue?.venue}`,
            callback: async function (result: Record<string, unknown>) {
                if (result.error) {
                    console.log("error occurred: " + result.error)
                    setPaymentStatus({
                        status: "DECLINED",
                        responseHeader: "Payment Failed",
                        responseBody:
                            "Payment failed, please verify your payment details and try again.",
                    })
                } else {
                    await Api.payments.confirmGuestPayment({
                        orderId: response.orderDetails.id,
                        paymentResponse: JSON.stringify(result),
                        paymentGatewayName: "Yoco",
                        paymentGatewayTransactionId: result.id as string,
                    })
                    navigate(`/payment/${response.orderDetails.id}/status`)
                }
                setModalShow(true)
            },
        })
    }

    return (
        <Container>
            {!event || !venue ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Row>
                        <Toaster
                            richColors={true}
                            closeButton={true}
                            position="top-right"
                            duration={10000}
                        />
                        <Col md={8}>
                            <h2 className="pt-2 pb-2">
                                {venue?.venue} Tickets
                            </h2>
                            {event?.eventTickets.map((ticket) => (
                                <Card key={ticket.eventId}>
                                    <Card.Body>
                                        <Card.Title>
                                            {ticket.ticketType} Tickets @ R
                                            {ticket.price.toFixed(2)} each
                                        </Card.Title>
                                        <Card.Text className="text-muted">
                                            {venue?.googleLocation},{" "}
                                            {venue?.province}
                                        </Card.Text>
                                        <ButtonGroup>
                                            <Button
                                                variant="dark"
                                                className="btn outline-dark"
                                                onClick={() =>
                                                    removeFromCart({
                                                        ticketId: ticket.id,
                                                        quantity: quantity - 1,
                                                        ticketType:
                                                            ticket.ticketType,
                                                        price: ticket.price,
                                                    })
                                                }
                                            >
                                                -
                                            </Button>
                                            <span className="lead px-2 font-weight-bold">
                                                {quantity}
                                            </span>
                                            <Button
                                                variant="dark"
                                                className="btn outline-dark"
                                                onClick={() =>
                                                    addToCart({
                                                        ticketId: ticket.id,
                                                        quantity: quantity + 1,
                                                        ticketType:
                                                            ticket.ticketType,
                                                        price: ticket.price,
                                                    })
                                                }
                                            >
                                                +
                                            </Button>
                                        </ButtonGroup>
                                    </Card.Body>
                                </Card>
                            ))}
                        </Col>
                        <Col md={4}>
                            <h5 className="mt-3 lead font-weight-bold">
                                Order Summary
                            </h5>
                            <hr />
                            {ticketCart.map((ticket, index) => (
                                <Row key={index}>
                                    <Col md={6}>
                                        <p className="lead">
                                            {ticket.quantity} x{" "}
                                            {ticket.ticketType}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <p className="lead">
                                            R{" "}
                                            {(
                                                ticket.price * ticket.quantity
                                            ).toFixed(2)}
                                        </p>
                                    </Col>
                                </Row>
                            ))}
                            <hr />
                            <Row>
                                <Col md={6}>
                                    <p className="font-weight-bold">
                                        Grand Total
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p className="font-weight-bold">
                                        R{" "}
                                        {ticketCart
                                            .reduce(
                                                (total, ticket) =>
                                                    total +
                                                    ticket.quantity *
                                                        ticket.price,
                                                0
                                            )
                                            .toFixed(2)}
                                    </p>
                                </Col>
                            </Row>
                            <h5 className="mt-4 lead font-weight-bold">
                                Billing Details
                            </h5>
                            <hr />
                            <Row>
                                <Col md={12}>
                                    <Form>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formFirstName"
                                        >
                                            <Form.Label>
                                                First name *
                                            </Form.Label>
                                            <Form.Control
                                                value={customer?.firstname}
                                                required
                                                onChange={(e) =>
                                                    setCustomer({
                                                        ...customer,
                                                        firstname:
                                                            e.target.value,
                                                    })
                                                }
                                                type="text"
                                                placeholder="Enter First name"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formLastName"
                                        >
                                            <Form.Label>Last name *</Form.Label>
                                            <Form.Control
                                                value={customer?.lastname}
                                                required
                                                onChange={(e) =>
                                                    setCustomer({
                                                        ...customer,
                                                        lastname:
                                                            e.target.value,
                                                    })
                                                }
                                                type="text"
                                                placeholder="Enter Last name"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formEmail"
                                        >
                                            <Form.Label>
                                                Email address *
                                            </Form.Label>
                                            <Form.Control
                                                value={customer?.email}
                                                required
                                                onChange={(e) =>
                                                    setCustomer({
                                                        ...customer,
                                                        email: e.target.value,
                                                    })
                                                }
                                                type="email"
                                                placeholder="Enter email"
                                            />
                                            <small className="text-muted">
                                                - Please ensure your email is
                                                correct
                                            </small>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formContact"
                                        >
                                            <Form.Label>
                                                Contact number *
                                            </Form.Label>
                                            <Form.Control
                                                value={customer?.contact}
                                                required
                                                onChange={(e) =>
                                                    setCustomer({
                                                        ...customer,
                                                        contact: e.target.value,
                                                    })
                                                }
                                                type="text"
                                                placeholder="Enter contact number"
                                            />
                                        </Form.Group>
                                        <Row>
                                            <Col md={12}>
                                                <Button
                                                    disabled={disablePayButton}
                                                    variant="dark"
                                                    className="btn btn-block"
                                                    onClick={handleCheckout}
                                                >
                                                    <Lock /> Proceed Payment
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <VerticalModal
                        heading={paymentStatus?.responseHeader || ""}
                        body={{
                            text: paymentStatus?.responseBody || "",
                            reference: paymentStatus?.reference,
                        }}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </>
            )}
        </Container>
    )
}

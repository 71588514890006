import { AxiosStatic } from "axios"
import { httpClient } from "../Interceptor"
import { CreateBookingApiRequest, GetBookingApiRequest } from "./types"


export class BookingService {
    readonly http: AxiosStatic

    constructor() {
        this.http = httpClient
    }

    /**
     * Makes a booking
     * @param request
     * @returns
     */
    async createBooking(request: CreateBookingApiRequest) {
        return this.http.post<{ error: string, bookingId: string }>(`/api/Bookings/hike-massage-booking`, request)
            .then((response) => response.data)
    }

        /**
     * Get booking details
     * @param bookingId - booking id
     * @returns 
     */
        async getBooking(bookingId: string): Promise<GetBookingApiRequest> {
            return this.http.get<any>(`/api/Bookings/booking?bookingId=${bookingId}`)
            .then((response) => response.data)
        }
}
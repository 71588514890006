import React, { useEffect, useState } from "react"
import { Button, ButtonGroup, Card, Col, Container, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { Api } from "../../api"
import { EventApiResponse, EventVenue } from "../../api/events"
import { CheckoutRequest } from "../../api/payment/types"
import { VerticalModal } from "../../components/modals"

type CartItem = {
    ticketId: string
    quantity: number
    ticketType: string
    price: number
}
export const CheckoutPage: React.FC = () => {
    const navigate = useNavigate()
    const params = useParams<{ eventId: string; venueId: string }>()

    const [yoco, _setYoco] = useState<any>((window as any).yoco)
    const [venue, setVenue] = useState<EventVenue>()
    const [event, setEvent] = useState<EventApiResponse>()
    const [eventId, _setEventId] = useState<any>(params.eventId)
    const [venueId, _setVenueId] = useState<any>(params.venueId)
    const [quantity, setQuantity] = useState<number>(0)
    const [ticketCart, setTicketCart] = useState<CartItem[]>([])
    const [paymentStatus, setPaymentStatus] = useState<{
        status: "APPROVED" | "DECLINED"
        responseHeader: string
        responseBody: string
        reference?: string
    }>()

    // Payment status modal
    const [modalShow, setModalShow] = useState(false)

    const addToCart = (item: CartItem) => {
        const index = ticketCart.findIndex(
            (ticket) => ticket.ticketId === item.ticketId
        )
        if (index > -1) {
            const newTicketCart = [...ticketCart]
            newTicketCart[index].quantity += 1
            setTicketCart(newTicketCart)
            setQuantity(newTicketCart[index].quantity)
        } else {
            setTicketCart([...ticketCart, item])
            setQuantity(1)
        }
    }

    const removeFromCart = (item: CartItem) => {
        const index = ticketCart.findIndex(
            (ticket) => ticket.ticketId === item.ticketId
        )
        if (index > -1) {
            const newTicketCart = [...ticketCart]
            newTicketCart[index].quantity -= 1
            setTicketCart(newTicketCart)
            setQuantity(newTicketCart[index].quantity)
        }
    }

    useEffect(() => {
        if (!yoco) throw new Error("Error: Yoco payment gateway not loaded")

        const getEventVenue = async () => {
            const response = await Api.events.getEventVenue(venueId)
            setVenue(response)
        }

        const getEvent = async () => {
            console.log(eventId)
            const response = await Api.events.getEventById(eventId)
            setEvent(response)
        }

        getEventVenue()
        getEvent()
    }, [eventId, venueId, yoco])

    const createOrder = async () => {
        const request: CheckoutRequest = ticketCart.map((item, index) => {
            return {
                eventId: event!.event.id,
                eventVenueId: venue!.id,
                price: item.price,
                ticketType: item.ticketType,
                quantity: item.quantity,
            }
        })
        const response = await Api.payments.createOrder(request)
        return response
    }

    const handleCheckout = async () => {
        const response = await createOrder()
        yoco?.showPopup({
            amountInCents: `${response.orderDetails.totalPrice * 100}`,
            currency: "ZAR",
            name: `${event?.event.eventName} @ ${venue?.venue}`,
            description: `Tickets for ${event?.event.eventName} @ ${venue?.venue}`,
            callback: async function (result: Record<string, unknown>) {
                if (result.error) {
                    console.log("error occurred: " + result.error)
                    setPaymentStatus({
                        status: "DECLINED",
                        responseHeader: "Payment Failed",
                        responseBody:
                            "Payment failed, please verify your payment details and try again.",
                    })
                } else {
                    await Api.payments.confirmPayment({
                        orderId: response.orderDetails.id,
                        paymentResponse: JSON.stringify(result),
                        paymentGatewayName: "Yoco",
                        paymentGatewayTransactionId: result.id as string,
                    })
                    navigate(`/payment/${response.orderDetails.id}/status`)
                }
                setModalShow(true)
            },
        })
    }

    return (
        <Container>
            {!event || !venue ? (
                <p>Loading...</p>
            ) : (
                <>
                    <Row>
                        <Col md={8}>
                            <h2 className="pt-2 pb-2">
                                {venue?.venue} Tickets
                            </h2>
                            {event?.eventTickets.map((ticket) => (
                                <Card key={ticket.eventId}>
                                    <Card.Body>
                                        <Card.Title>
                                            {ticket.ticketType} Tickets @ R
                                            {ticket.price.toFixed(2)} each
                                        </Card.Title>
                                        <Card.Text className="text-muted">
                                            {venue?.googleLocation},{" "}
                                            {venue?.province}
                                        </Card.Text>
                                        <ButtonGroup>
                                            <Button
                                                variant="dark"
                                                className="btn outline-dark"
                                                onClick={() =>
                                                    removeFromCart({
                                                        ticketId: ticket.id,
                                                        quantity: quantity - 1,
                                                        ticketType:
                                                            ticket.ticketType,
                                                        price: ticket.price,
                                                    })
                                                }
                                            >
                                                -
                                            </Button>
                                            <span className="lead px-2 font-weight-bold">
                                                {quantity}
                                            </span>
                                            <Button
                                                variant="dark"
                                                className="btn outline-dark"
                                                onClick={() =>
                                                    addToCart({
                                                        ticketId: ticket.id,
                                                        quantity: quantity + 1,
                                                        ticketType:
                                                            ticket.ticketType,
                                                        price: ticket.price,
                                                    })
                                                }
                                            >
                                                +
                                            </Button>
                                        </ButtonGroup>
                                    </Card.Body>
                                </Card>
                            ))}
                        </Col>
                        <Col md={4}>
                            <h5 className="mt-3 lead font-weight-bold">
                                Order Summary
                            </h5>
                            <hr />
                            {ticketCart.map((ticket, index) => (
                                <Row key={index}>
                                    <Col md={6}>
                                        <p className="lead">
                                            {ticket.quantity} x{" "}
                                            {ticket.ticketType}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <p className="lead">
                                            R{" "}
                                            {(
                                                ticket.price * ticket.quantity
                                            ).toFixed(2)}
                                        </p>
                                    </Col>
                                </Row>
                            ))}
                            <hr />
                            <Row>
                                <Col md={6}>
                                    <p className="font-weight-bold">
                                        Grand Total
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p className="font-weight-bold">
                                        R{" "}
                                        {ticketCart
                                            .reduce(
                                                (total, ticket) =>
                                                    total +
                                                    ticket.quantity *
                                                        ticket.price,
                                                0
                                            )
                                            .toFixed(2)}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Button
                                        disabled={ticketCart.length === 0}
                                        variant="danger"
                                        className="btn btn-block"
                                        onClick={handleCheckout}
                                    >
                                        Buy
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <VerticalModal
                        heading={paymentStatus?.responseHeader || ""}
                        body={{
                            text: paymentStatus?.responseBody || "",
                            reference: paymentStatus?.reference,
                        }}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </>
            )}
        </Container>
    )
}

import { AxiosStatic } from "axios"
import { httpClient } from "../Interceptor"
import { CheckoutRequest, GuestCheckoutRequest, OrderApiResponse } from "./types"

export class PaymentService {
    readonly http: AxiosStatic

    constructor() {
        this.http = httpClient
    }

    /**
     * Create a tickets order to be paid (authenticated user only)
     * @param request - order request body
     * @returns
     */
    async createOrder(request: CheckoutRequest): Promise<OrderApiResponse> {
        return this.http.post<any>(`/api/Payments/Checkout`, request)
        .then((response) => response.data)
    }

    /**
     * Create a tickets order to be paid (guest user)
     * @param request - order request body
     * @returns 
     */
    async createOrderForGuest(request: GuestCheckoutRequest): Promise<OrderApiResponse> {
        return this.http.post<any>(`/api/Payments/GuestCheckout`, request)
        .then((response) => response.data)
    }

    /**
     * Confirms an order (authenticated user only)
     * @param request
     * @returns
     */
    async confirmPayment(request: {
        orderId: string
        paymentResponse: string
        paymentGatewayName: string
        paymentGatewayTransactionId: string
    }): Promise<any> {
        return this.http.put<any>(
            `/api/Payments/ConfirmPayment?orderId=${request.orderId}&PaymentGatewayJsonString=${request.paymentResponse}&PaymentGatewayName=${request.paymentGatewayName}&PaymentGatewayTransactionId=${request.paymentGatewayTransactionId}`
        ).then((response) => response.data)
    }

    /**
     * Confirms an order (guest user)
     * @param request
     * @returns
     */
    async confirmGuestPayment(request: {
        orderId: string
        paymentResponse: string
        paymentGatewayName: string
        paymentGatewayTransactionId: string
    }): Promise<any> {
        return this.http.put<any>(
            `/api/Payments/ConfirmGuestPayment?orderId=${request.orderId}&PaymentGatewayJsonString=${request.paymentResponse}&PaymentGatewayName=${request.paymentGatewayName}&PaymentGatewayTransactionId=${request.paymentGatewayTransactionId}`
        ).then((response) => response.data)
    }
}

import { Col, Container, Row } from "react-bootstrap"
import { UIEventCard } from "../../components/cards/EventCard"
import { useEffect, useState } from "react"
import { Api } from "../../api"
import { EventApiResponse } from "../../api/events"
import { Toaster } from "sonner"

export const EventsPage: React.FC = () => {
    console.log("Rendering EventsPage")
    const [events, setEvents] = useState<EventApiResponse[]>([])

    useEffect(() => {
        const getEvents = async () => {
            const response = await Api.events.getEvents("")
            setEvents(response)
        }
        getEvents()
    }, [])
    return (
        <Container>
            <Toaster />
            <h2 className="pb-3">Upcoming Events</h2>

            {!events.length ? <p>Loading...</p> : <Row>
                {events.map((item, index) => (
                    <Col key={item.event.id} className="pb-4" md="4">
                        <UIEventCard
                            key={`${item.event.id}-${index}`}
                            event={item.event}
                            venue={item.venues[0]}
                            tickets={item.eventTickets}
                            image={
                                "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0b/c8/4b/6e/moses-mabhida-with-top.jpg?w=1200&h=-1&s=1"
                            }
                            link={`/events/${item.event.id}`}
                        />
                    </Col>
                ))}
            </Row>}
        </Container>
    )
}

export class LocalAuth {
    static isAuthenticated() {
        const jwt = localStorage.getItem("token")
        if (!jwt) {
            return false
        }
        const jwtData = jwt.split(".")[1]
        const decodedJwtJsonData = window.atob(jwtData)
        const decodedJwtData = JSON.parse(decodedJwtJsonData)
        const expirationUnix = decodedJwtData.exp
        const now = new Date().getTime()
        const currentUnix = Math.round(now / 1000)
        const expired = currentUnix > expirationUnix
        if (expired) {
            console.log("Token expired")
            localStorage.removeItem("token")
            return false
        }
        return true
    }
    static clearSession() {
        localStorage.removeItem("token")
    }
    static setSession(token: string) {
        localStorage.setItem("token", token)
    }
}
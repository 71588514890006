import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { Api } from "../../api"
import React, { useEffect, useState } from "react"
import { GetBookingApiRequest } from "../../api/bookings/types"
import { CheckCircle } from "react-bootstrap-icons"

export const BookingConfirmationPage: React.FC = () => {
    const { bookingId } = useParams<{ bookingId: string }>()
    const [booking, setBooking] = useState<GetBookingApiRequest | null>(null)

    useEffect(() => {
        const getBooking = async () => {
            if (!bookingId) return
            const response = await Api.bookings.getBooking(bookingId)
            setBooking(response)
        }
        getBooking()
    }, [bookingId])

    return (
        <Container style={{ paddingLeft: "15px", paddingRight: "15px", paddingTop:"30px", height: "100vh"}}>
            <div className="shadow" style={{ backgroundColor: "white", padding: "30px 10px", borderRadius: ".25rem"}}>
                {!booking ? (
                    <p>Loading...</p>
                ) : (
                    <Row className="text-center">
                        <Col md={12}>
                            <h2 className="pt-2 pb-2">
                                Booking Confirmation
                            </h2>
                            <CheckCircle size={64} color="green" />

                            <p className="lead pt-4">
                                Thank you for your booking! Please check your
                                email inbox and spam for your booking confirmation.
                            </p>
                            <p className="lead pb-4">
                                Reference:{" "}
                                <b>
                                    <code>{booking?.id.toUpperCase()}</code>
                                </b>
                            </p>
                        </Col>
                        <Col md={12}>
                            <h4 className="pt-4 pb-2">Order Details</h4>
                            <p>
                                <b>Client:</b> {booking?.firstname} {booking?.lastname}
                            </p>
                            <p>
                                <b>Massage Type:</b> {booking?.massageType}
                            </p>
                            <p>
                                <b>Booking Date:</b> {booking?.massageDate.substring(0,10)}
                            </p>
                        </Col>
                    </Row>
                )}
            </div>
        </Container>
    )
}

import { AxiosStatic } from "axios";
import { httpClient } from "../Interceptor";
import { Order } from "./types";

export class OrderService {
    readonly http: AxiosStatic

    constructor() {
        this.http = httpClient
    }

    /**
     * Get order details
     * @param orderId - order id
     * @returns 
     */
    async getOrder(orderId: string): Promise<Order> {
        return this.http.get<any>(`/api/Orders/GetOrder/${orderId}`)
        .then((response) => response.data)
    }
}